import { ButtonV3, TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import type { ReactNode } from 'react';

import Motion from '@/app/components/Motion';

import type {
  HomeHeroContentButtonProps,
  HomeHeroContentCopyProps,
} from './types';

export interface HomeHeroContentProps {
  readonly button: HomeHeroContentButtonProps;
  readonly copy?: HomeHeroContentCopyProps | undefined;
  readonly icon?: ReactNode | undefined;
}

const HomeHeroContent = ({
  button,
  copy,
  icon,
}: HomeHeroContentProps): JSX.Element => {
  const t = useTranslations();

  return (
    <Motion
      animate={{ opacity: 1 }}
      data-cy="home-hero-content"
      initial={{ opacity: 0 }}
      transition={{ duration: 1, type: 'tween' }}
    >
      {copy !== undefined && (
        <TypographyV3
          align="centerMdLeft"
          className={cx('mb-6', copy.className)}
          color={copy.variant ?? 'light'}
          data-cy="home-hero-content-copy"
          Element="p"
          variant="body-large"
        >
          {copy.value}
        </TypographyV3>
      )}
      <div className="mb-5 w-full space-y-6 md:w-fit lg:mb-12">
        <div className="flex justify-center md:justify-start">
          <ButtonV3
            {...button}
            className={cx(button.className)}
            data-cy="home-hero-content-button"
            variant={button.variant ?? 'primary'}
          >
            {t(button.label)}
          </ButtonV3>
        </div>
        <div className="flex justify-center md:justify-normal">{icon}</div>
      </div>
    </Motion>
  );
};

export default HomeHeroContent;
